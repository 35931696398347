<template>
  <!--begin::Menu-->
  <div
    class="
      menu
      menu-sub
      menu-sub-dropdown
      menu-column
      menu-rounded
      menu-gray-600
      menu-state-bg-light-primary
      fw-bold
      py-4
      fs-6
      w-400px
    "
    data-kt-menu="true"
  >

    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <div class="symbol-label fs-2 fw-bold text-primary">
            {{ state.initialContact }}
          </div>
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ state.contact.uti_c_prenom }} {{ state.contact.uti_c_nom }}
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{{
            state.contact.uti_c_mail
          }}</a>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <router-link to="/profil" class="menu-link px-5">
        Paramètres du compte
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="signOut()" class="menu-link px-5"> Déconnexion </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;
      state.initialContact =
        (state.contact.uti_c_prenom ? state.contact.uti_c_prenom.charAt(0) : "") +
        (state.contact.uti_c_nom ? state.contact.uti_c_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const signOut = () => {

      // console.log('Code ARKKKK', store.getters.currentUser.refCodeArk );

      const refCodeArk = store.getters.currentUser.refCodeArk;

      mAxiosApi.post("/logout");
      store
        .dispatch(Actions.LOGOUT)
        .then(() => { 
          //console.log(refCodeArk);         
          router.push({ name: "sign-in", params: { 'codeArk': refCodeArk } }) 
        } );
    };

    return {
      signOut,
      state,
    };
  },
});
</script>
