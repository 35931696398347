
import { defineComponent, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;
      state.initialContact =
        (state.contact.uti_c_prenom ? state.contact.uti_c_prenom.charAt(0) : "") +
        (state.contact.uti_c_nom ? state.contact.uti_c_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const signOut = () => {

      // console.log('Code ARKKKK', store.getters.currentUser.refCodeArk );

      const refCodeArk = store.getters.currentUser.refCodeArk;

      mAxiosApi.post("/logout");
      store
        .dispatch(Actions.LOGOUT)
        .then(() => { 
          //console.log(refCodeArk);         
          router.push({ name: "sign-in", params: { 'codeArk': refCodeArk } }) 
        } );
    };

    return {
      signOut,
      state,
    };
  },
});
