
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  emits: ["reloadRouter"],
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });
    
    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }
    

    return {
      asideTheme,
      t,
      reloadRouter,
    };
  },
});
