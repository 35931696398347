
import { defineComponent, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
import { useRouter, useRoute } from "vue-router";

import mAxiosApi from "@/api";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const breadcrumbs = store.getters.pageBreadcrumbPath;


    const state = reactive({
      bordSeq: store.getters.bordSeq,
      bordType : store.getters.bordType,
      menuId: 1,
    });



    // localStorage.setItem('storedData', this.input)

    watch(()=>store.getters.bordSeq, function() {
        state.bordSeq = store.getters.bordSeq;
        state.bordType = store.getters.bordType;
        changeMenu();
    });

    watch(()=>route.path, function() {
        changeMenu();
    });

    const affBord = (type) => {
      if (type == state.bordType) router.push({ name: "bord" });
    }

    const affDashboard = () => {
      localStorage.setItem('filterBordereau', "");
      
      if (route.path.indexOf("/board/overview") == -1 ) router.push({ name: "board" });
    }

   const changeMenu = () => {
      if (route.path.indexOf("/board/overview") >= 0 ) {
        state.menuId = 1;
        return;
      }
      if (route.path == "/bord/articles" || route.path == "/bord/identification" || route.path == "/bord/description" || route.path == "/bord/signatures") {
        if (state.bordType == 'versement') state.menuId = 2;
        if (state.bordType == 'élimination') state.menuId = 3;
        return;
      }
      state.menuId = -1;
   }

    onMounted(async () => {
        //
        changeMenu();
    });

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const backRoute = () => {
      router.go(-1);
    };

    const displayBack = () => {
      const mPath = route.path as any;
      // if (mPath.indexOf("/commande/") === 0) return true;
      return false;
    };

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      breadcrumbs,
      backRoute,
      displayBack,
      reloadRouter,
      state,
      affBord,
      affDashboard
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}

